
.right-panel-image {
    width: calc(100% - 250px);
    transition: all 0.2s;
    background: $color-gray-2;
    &:focus-visible {
        outline: -webkit-focus-ring-color auto 0px;
    }
}

.img-lst-hdr {
    display: inline-block;
    font-weight: normal;
    margin-bottom: 0;
    color: $color-secondary-hover;
    border: 0;
    padding: 8px;
}

.thumb_img {
    padding-bottom: calc(100% - 52px);
    text-align: center;
    vertical-align: middle;
    background-color: $color-gray-1 !important;
    background-size: 100% auto !important;
    background-position: center center !important;
    margin-bottom: 52px;
}

.img_name {
    width: 100%;
    @include text-hidden;
}

.div-img-list {
    float: left;
    cursor: pointer;
    position: relative;
    transition: all 0.2s;
    margin: 0 0 1rem 1rem !important;
    &:hover {
        border-color: $color-primary;
        background: $color-white;
        box-shadow: 0 0.15rem 1.75rem 0 rgba($color-light, 0.25) !important;

        .img-tool {
            display: block;
        }
    }
    .btn.btn-sm {
        min-width: 30px;
    }
}

.img-tool {
    display: none;
    transition: all 0.2s;
}

.toolDiv {
    position: absolute;
    right: 5px;
    top: 5px;
    width: 30px;
    transition: all 0.2s;
}

.m_lib {
    position: fixed;
    z-index: 1030;
    left: 0;
    right: 0;
    top: 0;
    transition: all;
    background: $color-white;
    animation: media 0.55s;
    height: 100%;
    -webkit-user-select:none;
    -moz-user-select:-moz-none;
    -ms-user-select:none;
    user-select:none
}


.m_lib_cnt {
    width: 100%;
    float: left;
}

.left-panel-tree {
    overflow-y: auto;
    box-sizing: border-box;
    height: calc(100vh - 65px);
    max-width: 250px;
    .dir {
        transition: all 0.2s;
        display: flex;
        .fa.fa-folder {
            width: 16px;
            padding-top: 3px;
        }

        .folder-name {
            @include text-hidden;
        }
        &.ui-active {
            background: $color-primary !important;
            color: $color-white !important;
            border-radius: 0;
            i {
                color: $color-white;
            }
        }
        i {
            margin-right: 3px;
        }
    }
}

.tdPrimaryKey {
    background: $color-danger;
    color: $color-white;
    width: 120px;
}

.thPrimaryKey {
    background: $color-primary;
    color: $color-white;
    width: 120px;
}
/*QPC-8342 CSS  */
.form-check-input {
    position: absolute;
    margin-top: 0rem;
    margin-left: -0.25rem
}

.child-chk {
    position: absolute;
    top: 0.4rem;
    left: 0.5rem;
}

.parent-chk {
    bottom: 0.5rem;
    left: 1rem;
}

.total-img-count {
    margin-left: 20px;
    margin-top: -10px;
}

/* css End QPC-8342 */