.msp-data-grid {
    .grid-table{
        width: auto;
        max-width: 0;
        background: $color-white;

        .filter-row th::before {
            position: absolute;
            content: '';
            top: -2px;
            height: 2px;
            background-color: $color-gray-1;
            left: -1px;
            right: 0;
        }
        td{
            &.text-found {
                background-color: rgba(49, 232, 0, 0.16);
            }
            .data-lable-mob{
                display: none !important;
            }
        }
        
        thead {
            position: sticky;
            top: 0px;
            background-color: $color-white;
            z-index: 4;
        }
        tr{
            th.grid-col-freeze:first-child,
            td.grid-col-freeze:first-child {
                left: -1px;
            }
            

        }
        tbody tr.selected{
            color: $color-black;
        }
        tbody tr.selected td.grid-col-freeze {
            background: rgb(237, 246, 253) !important;
            color: $color-light;
        }

    }

    .list-of-value-table td,
    .grid-table td {
        max-width: 0;
        vertical-align: middle;
    }
    .list-of-value-table th[data-field],
    .grid-table{
        th[data-field], 
        th[data-field] .d-flex{
            min-width: 100px;
        }
    }
    &.mode-edit{
        .grid-table{
            th[data-field], 
            th[data-field] .d-flex{
                min-width: 200px;
            }
        }
    }

    .head-row{
        th{
            border-top: 2px solid $color-gray-1;
            height: 29px;
            position: relative;

            &::before{
                position: absolute;
                content: '';
                top: -1px;
                height: 2px;
                background-color: $color-gray-1;
                left: -1px;
                right: 0;
            }
        }
        .grip {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            cursor: col-resize;
            width: 10px;
            display: none;
            z-index: 99;
        }

    }
    .cell-edit {
        display: none;
        width: 100%;
        white-space: normal !important;
        i.fa-lock {
            font-size: 10px;
            position: absolute;
            right: 10px;
            top: 30%;
        }
    }
    .cell-disp {
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
    td.wrap .cell-disp,
    .word-wrap {
        white-space: normal;
        overflow-wrap: break-word;
    }
    &.mode-edit{
        .hide-edit {
            display: none;
        }
        .cell-edit {
            display: block;
        }
    } 

    .group-child{
        &.default {
            display: none;
        }
        left: 0;
        transition: all 0.5s;
    }

    // &.reports {
    //     .page-top-button .btn.btn-add-object{
    //         display: none !important;
    //     }
    // }

    .blocked-user
        {
            text-decoration: line-through !important;
        }

        .select2-container--default .select2-results>.select2-results__options{
            max-height: 150px;
        }
        .multiselect-container.dropdown-menu{
            max-height: 160px;
            top:4px !important;
            max-width: 160px;
        }

        .search-in-list, .text-search-area{
            vertical-align: middle !important;
        }

        .select2-container--open{
            top:unset !important;
            bottom: 0 !important;
        }
        .select2-container--open .select2-dropdown--above{
            border-bottom: 1px solid $color-gray-1;
            border-bottom-left-radius: 4px;
            border-bottom-right-radius: 4px;
        }
        .select2-container--default .select2-selection--single{
            border-bottom: 1px solid $color-gray-1;
            border-top-left-radius: 4px !important;
            border-top-right-radius: 4px !important;
        }


}

.list-of-value-table {
    padding: 4px 4px 0 16px !important;
    // min-height: 200px;
}

.grid-col-freeze {
    position: sticky !important;
    background-color: $color-white !important;
    z-index: 3 !important;
}

.open-row {
    background: $color-primary;
    color: $color-white;
    transition: all 0.4s;
    .grid-col-freeze {
        background-color: $color-primary !important;
    }
    .grid-cell .row-icon {
        color: $color-white;
        display: block !important;
    }
    .lookup-display-record, a, .cell-disp a{
        color:$color-white;
        text-decoration: underline;
    }
    .cell-disp a{
        max-width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: block;
    }
    &.selected{
        .lookup-display-record, a, .cell-disp a{
            color:$color-primary;
            text-decoration: none;

            &:hover{
                text-decoration: underline;
            }
        }
        
    }
}

.mode-edit{

    .msp-ctrl-lookup{
        .btn-outline-secondary{
            border: 0 !important;
            padding: 0 !important;
            text-align: left;
            margin: 0;
            &:hover {
                background: $color-white;
                color: $color-secondary;
            }

            .fa-times {
                right: 0;
                top: 2px;
            }
        }
    }

    .grid-table tbody tr:hover .row-icon {
        display: none !important;
    }

    .wrap {
        overflow: visible;
    }

    .custom-user-invites {
        .btn-outline-secondary {
            border: 0 !important;
            padding: 0 !important;
            text-align: left;
            margin: 0;
            &:hover {
                background: $color-white;
                color: $color-secondary;
            }
            .fa-times {
                right: 0;
                top: 2px;
            }
        }
       
    }

}

.table-responsive.grid-table-container.tblUsers::before,
.table-responsive.grid-table-container.tblUsers::after {
    display: none;
}

.table.table-bordered.recipients-table .data-row.selected {
    background: rgba($color-primary-hover, 0.07);
}

.main-drive-list.grid-table-container::after {
    border-bottom: 0 !important;
}

.main-drive-list .grid-table thead {
    position: sticky;
    top: 0px;
    background-color: #fff;
    z-index: 4;
}

[data-pagename="OperaIntegrationConfiguration"] .grid-table-container {
    min-height: unset !important;
}
/*QPC-8766 CSS Hide Extra Line in UserProfile Detail Page */
.custom-parent-container .grid-table-container::after{
    border-bottom : 1px solid white !important
} 
/* Ends QPC-8766 */

td.grid-col-freeze::after,
th.grid-col-freeze::after {
    content: '';
    position: absolute;
    right: 0;
    width: 2px;
    height: 100%;
    background: $color-gray-1;
    top: 0;
}

td.grid-col-freeze::before,
th.grid-col-freeze::before {
    // According to QPC-7373 done by rishi with shashi chouhan
    content: '';
    position: absolute;
    right: 0;
    left: 0;
    height: 2px;
    background: $color-gray-1;
    bottom: -1px;
}

tr.filter-row::after {
    content: "";
    position: absolute;
    border-bottom: 2px solid $color-gray-1;
    width: 100%;
    bottom: 0;
    left: 0;
    z-index: 50;
}

.grid-table-container{

    position: relative;
    min-height: 300px;
    max-height: calc(100vh - 250px);
    border-left: 1px solid $color-gray-1;
    border-bottom: 1px solid $color-gray-1;

    // &::after {
    //     content: "";
    //     position: absolute;
    //     border-bottom: 1px solid $color-gray-1;
    //     width: 100%;
    //     height: 1px;
    //     bottom: 0;
    // }

    &.after-none::after{
        display:none;
    }

    .grid-table {
        position: relative;
        z-index: 5;
    }

    &::before {
        content: "";
        position: absolute;
        border-top: 2px solid $color-gray-1;
        border-bottom: 2px solid $color-gray-1;
        width: 100%;
        height: 31px;
        top: 0;
        display: none;
    }
}

.object-detail-tab {
    .grid-table-container{
        z-index: 0; // adding QPC-6884
    }
}

.grid-table{
    .head-row th,
    .filter-row th,
    td {
        padding: 0px;
        position: relative;
    }

    .head-row{
        th {
            &.wrap{
                overflow: visible;
            }
           
        }
        .d-flex:hover .grip {
            display: block;
            border-right: solid 3px #334554;
        }

    }

    .head-row th>div,
    .filter-row th>div,
    td>div {
        padding: 0.2rem 0.5rem;
        display: flex;
        box-sizing: border-box;
    }

    tbody tr{
        &:hover {
            box-shadow: inset 1px 0 0 #bfceec, inset -1px 0 0 #dfe7f7, 0 1px 2px 0 rgba(60, 64, 67, 0.3), 0 1px 3px 1px rgba(60, 64, 67, 0.15);
            z-index: 1;
        }
        &.selected {
            background: rgba($color-primary-hover, 0.07);
        }
    }

}

.grip{
    &.active{
    display: block !important;
    opacity: 1 !important;
    border-right: solid 3px #334554;
    left: 0;
    height: 100%;

    &::after {
        height: 400px;
        position: absolute;
        right: 0;
        top: 0;
        width: 1px;
        border-right: solid 1px #334554;
        display: block;
        content: " ";
    }
  }
}


.open-row-result {
    max-height: 200px;
    overflow-x: auto;
}

.open-row.selected .grid-cell .row-icon {
    color: $color-light;
}

.open-row-child {
    transition: all 0.4s;
    position: relative;
    z-index: 0;
    &>td{
        background: rgba(0, 126, 227, 0.07);
        padding: 1rem;
        transition: all 0.4s;
        padding: 1rem;
        box-shadow: 0px 7px 10px rgba(0, 0, 0, 0.15);

        table.page-table {
            background: $color-white;
            transition: all 0.4s;
        }
    }
    .page-table thead th, .page-table thead td {
        color: $color-primary;
    }
}

.head-row{
    background: $color-gray-2;
    th.group-main,
    th.group-child {
        border-bottom-color: #b1c1ce !important;
    }

    .sort-field {
        position: relative;
        background: transparent;
        width: 16px;
        height: 16px;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-style: normal;
        font-variant: normal;
        text-rendering: auto;
        line-height: 1;
        display: inline-block;
    }

    .sort-field:before,
    .sort-field:after {
        position: absolute;
        cursor: pointer;
        opacity: 0.35;
        font-size: 1rem;
        top: 3px;
    }

    .sort-field.asc:before,
    .sort-field.desc:after {
        color: $color-primary;
        opacity: 1;
    }
    
    .sort-field:before {
        right: 0em;
        content: "\f0de";
    }
    .sort-field:after {
        right: 0em;
        content: "\f0dd";
    }


    .expand,
    .text-wrap {
        width: 14px;
        height: 14px;
        text-align: center;
        border-radius: 2px;
        margin-left: 4px;
        font-size: 14px;
        line-height: 13px;
        margin-top: 4px;
        transition: all 0.2s;
        cursor: pointer;
        opacity: 0.35;
        display: inline-block;
    }

    .expand:hover,
    .text-wrap:hover {
        opacity: 1;
    }

    .field-text {
        flex-grow: 1 !important;
        position: relative;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    
}

.listview-display .table .head-row .field-text {
    flex-grow: unset !important;
}

.groupby-colpase-title.head-row {
    background: unset;
    color: $color-primary;
}

.grid-cell {
    position: relative;
    justify-content: space-between;
    display: flex;
    margin: auto;
    .row-icon {
        display: none !important;
        position: absolute;
        right: 0px;
        top: 2px;
        width: 14px;
        height: 14px;
        font-size: 14px;
        text-align: center;
        color: $color-primary;
        border-radius: 2px;
        box-sizing: border-box;
        padding-top: 3px;
        cursor: pointer;
        animation: arrowBranch 0.3s ease-in;
        &::before {
            top: 3px;
        }
    }

    .img-view-bg {
        width: 40px;
        height: 40px;
        object-fit: cover;
        cursor: pointer;
        border-radius: 50%;
        max-width: 100%;
        max-height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        overflow: hidden;
        background-color: $color-blue-tint;
        img { 
            width:100%;
            height:100%;
            object-fit: cover;
            object-position: center;
        }
    }

}


.grid-table tbody tr:hover .row-icon {
    display: inline-block !important;
}

.msp-data-list {
    .groupby-table .head-row th::before {
        height: 1px;
    }
    .head-row{
        .expand,
        .text-wrap {
            margin-left: 4px
        }
    }
     .grid-table th[data-field],
     .grid-table th[data-field] .d-flex {
        min-width: unset;
    }
      
}
// QPC-7102, 6616 Dropdown Z-index on Grid
td.grid-col-freeze.active-dropdown {
    z-index: 999 !important;
}

//Filter sorting dropdown with table grid

.table-filter{

    .edit-filter {
        cursor: pointer;
        margin-left: 10px;
        padding: 2px 12px 2px 3px;
        box-sizing: border-box;
        border-radius: 2px;
    }
    .dropdown-toggle::after {
        font-size: 20px;
        vertical-align: 0.1em;
    }
    .dropdown-item{
        &.active {
            background: none !important;
            color: $color-primary !important;
            position: relative;
            &::after {
                content: "\f00c";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                right: 10px;
                left: 5px;
            }
        }
        &.default-item {
            position: relative;

            &::before {
                content: "\f08d";
                font-family: "Font Awesome 5 Free";
                font-weight: 900;
                position: absolute;
                right: 10px;
                color: $color-light;
                font-size: 0.7rem;
                top: 10px;
            }
        }
    }
    .tasks-sublist-menu .dropdown-item.active::after {
        left: 15px;
    }
}

@media (max-width: 767px) {

    .msp-data-grid {
        .grid-table {
            width: auto;
            max-width: unset;
            background: $color-white;
            display: block !important;
            z-index: 0;
            height: 100%;
            max-height: 100vh;

            tr {
                border: 1px solid rgba(5, 93, 168, 16%);
                background-color: #f7f9fc;
                margin-bottom: 1rem;
                border-radius: .5rem;

                &.head-row {
                    position: absolute;
                    top: -9999px;
                    left: -9999px;
                }
                td:first-child{
                    display:none;
                }

            }

            td:last-child {
                border-bottom: none !important;
            }

            td, .filter-row th{
                    // Behave like a "row"
                    border: none;
                    border-bottom: 1px solid $color-gray-1 !important;
                    position: relative;
                    padding-left: 50%;
                    min-height: 32px;
                    max-width: unset;
             }

            .filter-row {
                position: relative;
                width: 100%;
                z-index: 10;
                height: 100%;
                overflow: auto;
                transition: all .3s;
                border-radius: 0 !important;
                border: 0 !important;
                display: none;
                border-radius: .5rem;

                &.active{
                    
                        display: block;
                    
                }

                th::before {
                    content: attr(data-label);
                    height: auto;
                    top: 6px !important;
                    background: none;
                }
            }

            td::before, .filter-row th::before {
                content: attr(data-label);
                /* Now like a table header */
                position: absolute;
                /* Top/left values mimic padding */
                top: 4px;
                left: 6px;
                width: 45%;
                padding-right: 10px;
                white-space: nowrap;
                color: $color-primary;
                font-weight: 500;
                display: block !important;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            
        }


         .grid-table, .grid-table thead, .grid-table tbody, .grid-table th, .grid-table td, .grid-table tr {
            display: block;
        }


    }

    .grid-col-freeze {
        position: relative !important;
        background-color: transparent !important;
        z-index: 3 !important;
        left: unset !important;
    }
    td.grid-col-freeze::after,
    th.grid-col-freeze::after{
        background: none;
        top: 0;
    }

    #secondry-navs {
        display: none;
    }

    .user-profile-detail .msp-data-grid .grid-table tr td:first-child {
        display: block;
    }

    .msp-data-grid.mb-5 {
        margin-bottom: 0 !important;
    }
    .AdminTagList.msp-data-grid .grid-single-deleteBtn {
        display: none;
    }
    [data-uid="UserList"] .msp-data-grid .grid-table tr td:first-child {
        display: block !important;
    }
    .grid-table tbody tr:hover {
        box-shadow: none !important;
    }
    .grid-table-container {
        max-height: calc(100vh - 225px);
        min-height: unset;
        border: none;
        &::before {
            display: none;
        }

    }

    .grid-table tbody tr:hover .row-icon,
    .grid-table tbody tr:hover td[data-label="ID"] .row-icon {
        display: none !important;
    }

    tr.filter-row::after {
        display: none;
    }
    .grid-table-container+nav.hide-edit {
        position: fixed;
        bottom: 0;
        padding: 1rem 0.5rem;
        left: 0;
        background: $color-white;
        right: 0;
        overflow: auto;
    }

    .grid-table-container+nav.hide-edit .pagination {
        margin-bottom: 0;
    }

    .grid-table-container::after {
        display: none;
    }
}
